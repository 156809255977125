<template>
  <div class="collect">
    <pageHead title="我的收藏" />
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
      class="collect_content">
      <topic
        @like="like($event, index)"
        @favorite="favorite($event, index)"
        @comment="handleComment"
        v-for="(item, index) in topicData"
        :key="item.id"
        :data="item"
        class="topicItem"
        @userInfoClick="handleToUserDetail(item)"
        @share="handleShare"
        @click="handleTopicClick"
        @follow="handleFollow(item, index)"
        @originalClick="handleTopicClick"
        @handleNewActive="handleNewActive"
      />
      <no-more
        v-if="!listQueryParams.hasNextPage && topicData.length"
        text="没有更多数据"
        class="no-more"
      />
      <van-empty v-if="isLoad && !topicData.length" description="暂无数据" />
    </div>
    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-relation
      hide-img
      @publish="handlePublishComment"
    />
  </div>
</template>

<script>
import { myFavorites } from '@/api/favorites'
import { addThumbsup, removeThumbsup } from '@/api/thumbsup'
import { addFavorites, removeFavorites } from '@/api/favorites'
import commentToolDialog from '@/components/commentToolDialog'
import pageHead from '@/components/pageHead'
import topic from '@/components/topicItem'
import NoMore from '@/components/uitls/NoMore'
import { addComment } from '@/api/comment'
import { attAdd, followRemove } from '@/api/att'
const ENTITY_TYPE_ARTICLE = 2

export default {
  components: {
    commentToolDialog,
    pageHead,
    topic,
    NoMore
  },
  data() {
    return {
      showDialog: false,
      commentParams: {},
      commentContent: '',
      isAnonymousComment: false,
      pageNum: 1,
      pageSize: 20,
      topicData: [],
      isLoad: false,
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true
      }
    }
  },
  methods: {
    loadMore() {
      this.loadList()
    },
    async handleFollow(data) {
      const { id, myFollow } = data
      if (myFollow) {
        await followRemove(id)
        data.commonFollowCount--
      } else {
        await attAdd(id)
        data.commonFollowCount++
      }
      data.myFollow = !myFollow
    },
    /**
     * 添加评论
     * @param item
     */
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
    },
    async handlePublishComment() {
      const {
        commentContent: content,
        commentParams: { articleId: entityId },
        isAnonymousComment: isAnonymous
      } = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({ message: '评论内容不能为空', type: 'danger' })
        return
      }
      await addComment({ content, entityId, entityType, isAnonymous })
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ''
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item
        }
        const { articleStatisticVO } = item
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1
          }
        }
      })
    },

    loadList() {
      let listQueryParams = this.listQueryParams
      listQueryParams.userId = this.$store.getters.user.user_id
      if (listQueryParams.hasNextPage) {
        myFavorites(this.listQueryParams)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages
            listQueryParams.pageNum = listQueryParams.pageNum + 1
            let resData = this.topicData
            res.records.forEach((item) => {
              let count = resData.filter(
                (innerItem) => innerItem.entityId === item.entityId
              )
              if (count.length === 0) {
                resData.push(item.entityDetail)
              }
            })
            this.topicData = resData
            this.isLoad = true
          })
          .catch(() => {
            this.isLoad = true
          })
      }
    },
    like(res, index) {
      // 取消点赞
      if (res.isThumbsUp) {
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isThumbsUp = !res.isThumbsUp
          let count = data.articleStatisticVO.thumbsUpCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.thumbsUpCount = count
        })
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.articleId,
          entityType: 1
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isThumbsUp = !res.isThumbsUp
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {}
          }
          data.articleStatisticVO.thumbsUpCount =
            data.articleStatisticVO.thumbsUpCount + 1
        })
      }
    },

    favorite(res, index) {
      console.log(res)
      // 取消收藏
      if (res.isFavorites) {
        removeFavorites({
          entityId: res.articleId,
          entityType: 1
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isFavorites = !res.isFavorites
          let count = data.articleStatisticVO.favoritesCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.favoritesCount = count
        })
      } else {
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: 1
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isFavorites = !res.isFavorites
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {}
          }
          data.articleStatisticVO.favoritesCount =
            data.articleStatisticVO.favoritesCount + 1
        })
      }
    },
    handleTopicClick({ articleId } = {}) {
      // if(status === 0) {
      this.$router.push({ name: 'topicDetail', query: { id: articleId } })
      // }
    },
    handleNewActive(item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId
      })
      console.log(this.topicData[index])
      this.topicData[index].articleVoteVO = item
    },
    /**
     * 分享事件
     */
    handleShare({ id: quoteId } = {}) {
      this.$router.push({ name: 'createTopic', query: { quoteId } })
    },
    handleToUserDetail(item) {
      this.$router.push({name: 'userInfoDetail', params: {userId: item.userBasicVO.userId}})
    },
  }
}
</script>

<style lang="scss" scoped>
.collect {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &_content {
    flex: 1;
    position: relative;
    overflow-y: auto;

    .topicItem {
      margin-top: 10px;
    }

    .no-more {
      margin-top: 10px;
    }
  }
}
</style>
